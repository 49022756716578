<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <h5 slot="header" class="mb-0">
              <b-button
                size="sm"
                :to="{ name: 'admin.loan-repayments' }"
                class="mr-2"
                ><i class="fa fa-chevron-left"></i> Буцах</b-button
              >
              <span>Төлбөр бүртгэх</span>
            </h5>
            <b-form-group
              id="input-group-amount"
              label="Дүн:"
              label-for="input-amount"
            >
              <money
                id="input-amount"
                class="form-control"
                v-model="form.amount"
                type="text"
                required
                placeholder="Дүн"
              ></money>
            </b-form-group>

            <b-form-group
              id="input-group-transactionDate"
              label="Төлсөн огноо:"
              label-for="input-transactionDate"
            >
              <!-- <datetime  v-model="form.transactionDate" input-class="form-control" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="Сонгоно уу" required></datetime > -->
              <date-picker
                id="input-transactionDate"
                v-model="form.transactionDate"
                :config="{ format: 'YYYY-MM-DD HH:mm:ss', useCurrent: false }"
                required
                placeholder="Төлсөн огноо"
              ></date-picker>
            </b-form-group>

            <b-form-group
              id="input-group-payDescription"
              label="Гүйлгээний утга:"
              label-for="input-payDescription"
            >
              <b-form-input
                id="input-payDescription"
                v-model="form.payDescription"
                required
                placeholder="Гүйлгээний утга"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-payBank"
              label="Төлсөн банк:"
              label-for="input-payBank"
            >
              <b-form-input
                id="input-payBank"
                v-model="form.payBank"
                placeholder="Төлсөн банк"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-payAccount"
              label="Төлсөн данс:"
              label-for="input-payAccount"
            >
              <b-form-select
                id="input-payAccount"
                v-model="form.payAccountNumber"
                placeholder="Төлсөн данс"
                :options="accountList"
              ></b-form-select>
            </b-form-group>

            <div>
                    <b-form-group
                        id="input-group-payDescription"
                        label="Утасны дугаар"
                        label-for="input-username"
                    >
                        <money
                        id="input-username"
                        class="form-control"
                        :class="{
                            'border-danger': !selectCustomer,
                            'border-primary': selectCustomer,
                        }"
                        v-model="username"
                        type="text"
                        required
                        placeholder="Утасны дугаар"
                        suffix=""
                        thousands=""
                        :precision="0"
                        ></money>
                    </b-form-group>
                </div>
            <div v-if="selectCustomer">
                    <b-alert show>
                        <b-button
                            :to="{
                            name: 'admin.customer.detail',
                            params: { id: selectCustomer.id },
                            }"
                            target="_blank"
                            class="pull-right "
                            size="sm"
                            variant="info"
                            >Дэлгэрэнгүй</b-button
                        >
                        <h4 class="mb-0">{{ selectCustomer.lastname }} {{ selectCustomer.firstname }} /{{ selectCustomer.registerNumber }}/ </h4>
                    </b-alert>

                    <b-form-group id="input-group-4">
              <b-form-checkbox value="true" v-model="form.isClose" switch
                >Хаах эсэх</b-form-checkbox
              >
            </b-form-group>
            <div v-show="form.isClose && form.customer">
              <label for="">Идэвхтэй зээл</label>

              <b-table bordered small :fields="columns" :items="accounts">
                <template v-slot:head(checkbox)>
                  <b-form-checkbox v-model="selectAll"></b-form-checkbox>
                </template>
                <template v-slot:cell(loanType)="data">
                  {{ $store.getters.loan_type[data.item.typeCode] }}
                </template>
                <template v-slot:cell(checkbox)="data">
                  <b-form-checkbox
                    type="checkbox"
                    :value="data.item"
                    v-model="form.accounts"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>

            <b-form-group
              id="input-group-description"
              label="Эргэн төлөлтийн хуваарь:"
              label-for="input-description"
              class="mb-0"
              v-show="!form.isClose && form.customer"
            >
              <div class="form-control mb-3">
                <strong class="">{{ schedules.paymentDate }} </strong> -
                <strong class="text-danger"
                  >{{ schedules.paymentAmount }}₮</strong
                >
              </div>
            </b-form-group>

            </div>
            <div v-else>
                <b-alert variant="warning" show>
                    Хэрэглэгч байхгүй байна
                </b-alert>
            </div>
            <b-form-group
              id="input-group-description"
              label="Тайлбар:"
              label-for="input-description"
              class="mb-0"
            >
              <b-form-input
                id="input-description"
                v-model="form.description"
                placeholder="Тайлбар"
              ></b-form-input>
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3"
                >Шинэчлэх</b-button
              >
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "LoanRepayments.new",
  components: {},
  data: function() {
    return {
      username: "",
      selectCustomer: null,
      isLoading: false,
      isLoadingCustomer: false,
      isLoadingAccounts: false,
      form: {
        amount: "",
        transactionDate: "",
        payDescription: "",
        payBank: "",
        payAccountNumber: "",
        isClose: false,
        customer: null,
        description: "",
        userId: this.$store.getters.user.id,
        isCreate: true,
        accounts: [],
      },
      show: true,
      customers: [],
      accounts: [],
      schedules: {
        paymentDate: "",
        paymentAmount: "0",
        fee: "0",
        amount: "0",
      },
      accountList: [],
      columns: [
        { label: "", key: "checkbox" },
        { label: "Зээлийн төрөл", key: "loanType" },
        { label: "Дансны дугаар", key: "accountNumber" },
        {
          label: "Авсан хэмжээ",
          key: "loanAmount",
          class: "text-right",
          sortable: true,
          sort_key: "loan_amount"
        },
        {
          label: "Үлдэгдэл",
          key: "loanBalance",
          class: "text-right",
          sortable: true,
          sort_key: "loan_balance"
        },
        {
          label: "Авсан огноо",
          key: "loanDate",
          sortable: true,
          sort_key: "loan_date"
        },
        {
          label: "Хаах огноо",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date"
        }
      ],
      selected: [],
      selectAll: false,
    };
  },
  created: function() {
    this.checkSession();
    this.getAccountList();
  },
  watch: {
    "form.isClose": {
      handler(isClose) {
        if(isClose) {
          this.loadAccounts();
        } else {
          this.payRepayment();
        }
      },
      deep: true,
    },
    "username": {
      handler(username) {
        let _username = username.toString();
        this.$data.selectCustomer = null
        if (_username && (_username.length > 7 || _username.length > 10) ) {
            this.getCustomerShort(_username);
        }
      },
    },
    selectAll: {
      handler(selectAll) {
        this.$data.form.accounts = [];
        if (this.selectAll) {
          this.$data.form.accounts = this.$data.accounts
        }
      },
      deep: true
    },
    'form.accounts': {
      handler(accounts) {
        if(!this.selectAll) {
          if(this.$data.form.accounts.length == this.$data.accounts.length) {
            this.selectAll = true
          }
        } 
      },
      deep: true
    },
  },
  methods: {
    getCustomerShort: function(phone) {
      this.isLoading = true;
      this.$data.selectCustomer = null;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.selectCustomer = response.data;
              this.$data.form.customer = this.$data.selectCustomer
              this.$data.accounts = []
              this.$data.form.isClose = false
              this.payRepayment();
            } else {
              this.showToast(
                "Анхаар",
                phone + " утасны дугаартай хэрэгдэгчийн мэдээлэл байхгүй байна",
                "warning"
              );
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    loadAccounts: function() {
      if (this.$data.form.isClose && this.$data.form.customer) {
        this.accounts = [];
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_active_loan_list_short",
            {
              params: {
                id: this.$data.form.customer.id
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              console.log(response)
              this.isLoadingAccounts = false;
              this.$data.accounts = response.body.data;
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    payRepayment: function() {
      if (!this.$data.form.isClose && this.$data.form.customer) {
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_repayment_schedule",
            {
              params: {
                id: this.$data.form.customer.id,
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              //console.log(response)
              if (response.body.data.length > 0) {
                this.$data.schedules.paymentDate =
                  response.body.data[0].paymentDate;
                this.$data.schedules.paymentAmount =
                  response.body.data[0].paymentAmount;
                this.$data.schedules.fee = response.body.data[0].fee;
                this.$data.schedules.amount = response.body.data[0].amount;
              }
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    getAccountList: function() {
      axios
        .get(this.$config.NUMUR_MS_ADMIN_URL + "get_bank_account_list", {
          headers: {
            ...this.$store.getters.httpHeader, // Include headers from the store
            'Content-Type': 'application/json', // Ensure Content-Type is application/json
          },
        })
        .then(
          (response) => {
            this.isLoadingAccounts = false;
            this.$data.accountList = response.data.data;
            //console.log(response)
          },
          (response) => {
            this.isLoadingAccounts = false;
          }
        );
    },
    onSubmit(evt) {
      if (this.$data.form.customer != null) {
        this.isLoading = true;
        this.$data.form.token = this.$store.getters.token;
        this.$data.form.email = this.$store.getters.email;

        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/update_payment",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.onReset(evt);
              } else {
                this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      } else {
        this.showToast("Алдаа", "Хэрэглэгч сонгоогүй байна", "warning");
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$data.form = {
        amount: "",
        transactionDate: "",
        payDescription: "",
        payBank: "",
        isClose: false,
        customer: null,
        description: "",
        userId: this.$store.getters.user.id,
        isCreate: true,
        accounts: [],
      };
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    customerName({ lastname, firstname, register, phone }) {
      return `${lastname} — ${firstname} — ${register} — ${phone}`;
    },
  },
};
</script>
